import React, { useState } from 'react';
import { FaQuestionCircle, FaChevronDown, FaChevronUp } from 'react-icons/fa';

const Faq = () => {
    const [openIndex, setOpenIndex] = useState(null);

    const faqs = [
        {
            question: "What is an Instagram Video Downloader?",
            answer: "An Instagram Video Downloader is an online tool that allows you to download videos, reels, photos, IGTV, and carousel posts from Instagram. With InstaDl, you can save your favorite content for offline viewing effortlessly.",
        },
        {
            question: "What type of file formats does InstaDl support for downloading?",
            answer: "InstaDl supports downloading images in JPG format and videos in MP4 format, ensuring you get high-quality files while keeping sizes manageable.",
        },
        {
            question: "Which devices can I use the InstaDl Downloader on?",
            answer: "You can use InstaDl on any device with a browser, including smartphones, tablets, laptops, and desktops. It's compatible with major operating systems like Mac OS, Windows, iOS, and Linux.",
        },
        {
            question: "Are there any limits on the number of videos I can download?",
            answer: "No limits! Download as many videos as you wish without any restrictions.",
        },
        {
            question: "Can I save other than video content on this site?",
            answer: "Absolutely! You can download photos, videos, reels, and IGTV posts for free, but InstaDl currently only supports content from Instagram.",
        },
        {
            question: "How to Download Instagram Photos and Videos Online?",
            answer: (
                <ol className='px-10 text-[18px] font-normal text-[#000000B3] leading-[28px] list-decimal'>
                    <li>Copy the URL of the Instagram video or photo.</li>
                    <li>Return to InstaDl, paste it into the provided field, and click Download.</li>
                    <li>Choose your desired quality, and your download will begin shortly.</li>
                </ol>
            ),
        },
        {
            question: "Can I Download Photos and Videos from Any User?",
            answer: "Yes, you can download content from public accounts only. We respect users' privacy, so downloading from private accounts is not allowed. However, we're working on a browser extension to assist with private content.",
        },
        {
            question: "What is the highest quality of images and videos I can download?",
            answer: "You can download images at a maximum resolution of 1080x1350 pixels and videos typically in HD (720p), depending on the original quality uploaded by users.",
        },
        {
            question: "Is downloading Instagram Reels allowed?",
            answer: "Yes, you can download Instagram Reels from personal accounts for personal use. However, commercial use of downloaded content is not permitted.",
        },
    ];

    const toggleFAQ = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    return (
        <div className='px-5 py-10 sm:px-24 md:px-32 lg:px-48' id='faq'>
            <div className='flex flex-col items-center'>
                <h3 className='py-4 text-[#1d4ed8] text-[25px] sm:text-[32px] font-bold text-center'>
                    Frequently Asked Questions (FAQ)
                </h3>

                <div className='w-full h-[2px] bg-gradient-to-tr from-[#1d4ed8] to-pink-600' />

                <p className='py-4 text-[18px] font-normal text-[#000000B3] leading-[28px] text-center'>
                    This FAQ addresses common questions about the InstaDl.app downloader. If you can't find the answer to your question, feel free to reach out via our contact page.
                </p>

                <div className='py-4 w-full max-w-2xl'>
                    {faqs.map((faq, index) => (
                        <div key={index} className='border-b border-[#ddd] mb-2'>
                            <div className='flex justify-between items-center py-3 cursor-pointer' onClick={() => toggleFAQ(index)}>
                                <h3 className='flex items-center text-[#1d4ed8] text-[18px] leading-[28px] font-bold'>
                                    <FaQuestionCircle className='mr-2' />
                                    {faq.question}
                                </h3>
                                {openIndex === index ? (
                                    <FaChevronUp className='text-[#1d4ed8]' />
                                ) : (
                                    <FaChevronDown className='text-[#1d4ed8]' />
                                )}
                            </div>
                            {openIndex === index && (
                                <div className='pb-3 text-[18px] font-normal text-[#000000B3] leading-[28px]'>
                                    {faq.answer}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Faq;

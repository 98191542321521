import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {

    return (
        <>

            <div className='bg-[#f5f9ff] w-full py-10 border-blue-300 border-t-[2px] gap-8 flex flex-col items-center '>


                <h2 className='text-[30px] font-semibold gradient-text '>InstaDl</h2>

                <div className=' w-[80%] gap-2 flex items-center flex-wrap justify-center '>
                    <a href="" className=' hover:text-gray-400 '>Video</a>
                    <span>|</span>
                    <a href="" className=' hover:text-gray-400 '>Photo</a>
                    <span>|</span>
                    <a href="" className=' hover:text-gray-400 '>Reels</a>
                    <span>|</span>
                    <a href="" className=' hover:text-gray-400 '>Story</a>
                    <span>|</span>
                    <a href="" className=' hover:text-gray-400 '>Viewer</a>
                    <span>|</span>
                    <a href="" className=' hover:text-gray-400 '>IgTV</a>
                    <span>|</span>
                    <a href="" className=' hover:text-gray-400 '>Carousel</a>
                    <span>|</span>
                    <Link to={'/contact'} className=' hover:text-gray-400 '>Contact</Link>
                    <span>|</span>
                    <Link to={'/privacy-policy'} className=' hover:text-gray-400 '>Privacy Policy</Link>
                    <span>|</span>
                    <Link to={'/terms-conditions'} className=' hover:text-gray-400 '>Terms & Conditions</Link>
                   
                </div>

                <div className='w-[80%] sm:w-[50%] py-3 flex justify-center items-center border-t-[1px] border-[#d0d0d0] '>
                    <h3 className='text-[14px] font-thin'>© 2020-2024 InstaDl. All rights reserved.</h3>
                </div>


            </div>


        </>
    )
}

export default Footer

import React, { useRef, useState } from "react";
import axios from "axios";
import { FaPaste } from "react-icons/fa";
import { MdOutlineClear } from "react-icons/md";

const InputArea = () => {
    const [apiResponse, setApiResponse] = useState(null); // Will store the blob URL directly
    const [inputValue, setInputValue] = useState('');
    const [loading, setLoading] = useState(false);
    const videoRef = useRef(null);

    const handleClear = () => {
        setInputValue('');
    };

    const fetchData = async () => {
        try {
            setLoading(true);
            const response = await axios.post('https://instagram-api-sable-three.vercel.app/download', { url: inputValue });
            const downloadUrl = response?.data.urls[0]; // Assuming the download URL is in the data
            if (downloadUrl) {
                // Fetch the actual video as a blob
                const videoResponse = await axios.get(downloadUrl, { responseType: 'blob' });
                const blobUrl = URL.createObjectURL(new Blob([videoResponse.data], { type: 'video/mp4' }));
                setApiResponse(blobUrl); // Store the blob URL for the video and download
            }

            handleClear();
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleDownload = () => {
        if (apiResponse) {
            const a = document.createElement('a');
            a.href = apiResponse; // Use the blob URL for download
            a.download = inputValue; // Specify the file name for download
            a.click();
        }
    };

    const handlePaste = async () => {
        try {
            const text = await navigator.clipboard.readText();
            setInputValue(text);
        } catch (err) {
            console.error('Failed to read clipboard contents: ', err);
        }
    };

    // State to track the current selection
    const [currentSelection, setCurrentSelection] = useState({
        title: 'Instagram Downloader',
        description: 'Download Instagram Videos, Photos, Reels, IGTV',
    });

    // Function to handle click events
    const handleClick = (type) => {
        let title = 'Instagram Downloader';
        let description = '';

        switch (type) {
            case 'video':
                description = 'Download Instagram Videos';
                break;
            case 'photo':
                description = 'Download Instagram Photos';
                break;
            case 'reel':
                description = 'Download Instagram Reels';
                break;
            case 'igtv':
                description = 'Download Instagram IGTV Videos';
                break;
            case 'carousel':
                description = 'Download Instagram Carousel Posts';
                break;
            case 'story':
                description = 'Download Instagram Stories';
                break;
            default:
                description = 'Download Instagram Videos, Photos, Reels, IGTV';
                break;
        }

        setCurrentSelection({ title, description });
    };
    return (
        <>
            <div className=" sm:py-16 p-4 bg-gradient-to-br from-[#c95c8d] via-[#c25385] to-[#6b67dc] gap-3 sm:gap-7 flex flex-col items-center ">

                <div className="md:w-[80%] lg:w-[70%] xl:w-[60%] px-4 flex justify-between items-center rounded-[6px] bg-[#e3e3e32f] backdrop-blur-lg ">

                    <a href="/" onClick={() => handleClick('video')} className="w-[14.2%] h-full py-[8px] sm:py-[6px] px-[14px] md:px-[10px] gap-[6px] flex justify-center items-center text-white border-r-[2px] border-[#ffffff50] group">
                        <div className="gap-[6px] flex justify-center items-center group-hover:opacity-70 transition-all duration-500 ">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none"><g stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.3"><path d="m8.316 7.043 2.875 1.701a.556.556 0 0 1 0 .956l-2.875 1.701a.555.555 0 0 1-.839-.479v-3.4a.556.556 0 0 1 .839-.479Z" /><path d="M13.544 15.889H4.656a2.222 2.222 0 0 1-2.223-2.222v-8.89a2.222 2.222 0 0 1 2.223-2.221h8.889a2.222 2.222 0 0 1 2.222 2.222v8.889a2.222 2.222 0 0 1-2.223 2.222Z" /></g></svg>
                          <span className=" hidden md:flex" >Video</span>
                        </div>
                    </a>
                    <a href="/" onClick={() => handleClick('Photo')}  className="w-[14.2%] h-full py-[8px] sm:py-[6px] px-[14px] md:px-[10px] gap-[6px] flex justify-center items-center text-white border-r-[2px] border-[#ffffff50] group">
                        <div  className="gap-[6px] flex justify-center items-center group-hover:opacity-70 transition-all duration-500 ">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none"><path stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.3" d="M14.878 15.333H3.03a1.481 1.481 0 0 1-1.481-1.481V3.482A1.481 1.481 0 0 1 3.03 2h11.848a1.481 1.481 0 0 1 1.481 1.481v10.37a1.481 1.481 0 0 1-1.481 1.482Z" /><path fill="#fff" d="M7.039 5.397a1.482 1.482 0 1 1-2.06 2.132 1.482 1.482 0 0 1 2.06-2.132Z" /><path stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.3" d="m16.361 11.63-3.125-2.5a.742.742 0 0 0-1.041.115l-2.472 3.089a.742.742 0 0 1-1.041.116l-1.384-1.108a.74.74 0 0 0-1.032.105l-3.238 3.886" /></svg>
                            <span className=" hidden md:flex" >Photo</span>
                        </div>
                    </a>
                    <a href="/" onClick={() => handleClick('Reels')} className="w-[14.2%] h-full py-[8px] sm:py-[6px] px-[14px] md:px-[10px] gap-[6px] flex justify-center items-center text-white border-r-[2px] border-[#ffffff50] group">
                        <div  className="gap-[6px] flex justify-center items-center group-hover:opacity-70 transition-all duration-500 ">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none"><g stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.3"><path d="M14.837 15.778H2.985a1.481 1.481 0 0 1-1.481-1.482V3.926a1.482 1.482 0 0 1 1.481-1.482h11.852a1.482 1.482 0 0 1 1.482 1.482v10.37a1.481 1.481 0 0 1-1.482 1.482ZM1.504 6.148h14.815M5.948 6.148l.74-3.704M11.133 6.148l.74-3.704" /></g></svg>
                            <span className=" hidden md:flex" >Reels</span>
                        </div>
                    </a>
                    <a href="/" onClick={() => handleClick('Story')} className="w-[14.2%] h-full py-[8px] sm:py-[6px] px-[14px] md:px-[10px] gap-[6px] flex justify-center items-center text-white border-r-[2px] border-[#ffffff50] group">
                        <div  className="gap-[6px] flex justify-center items-center group-hover:opacity-70 transition-all duration-500 ">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none"><g stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.3"><path d="M1.991 6.741a7.12 7.12 0 0 1 2.233-3.16M1.578 9.112c0 .832.15 1.627.413 2.37M14.661 3.112A1.334 1.334 0 1 1 12.773 5a1.334 1.334 0 0 1 1.888-1.887Z" /><path d="M12.681 3.227A7.105 7.105 0 0 0 6.62 2.31M7.852 6.787 10.919 8.6a.591.591 0 0 1 0 1.02l-3.067 1.814a.593.593 0 0 1-.894-.51V7.297a.593.593 0 0 1 .894-.51ZM4.224 14.62a7.036 7.036 0 0 1-.939-.894M6.619 15.914a7.108 7.108 0 0 0 8.42-3.604c.5-.992.76-2.088.76-3.199 0-.429-.043-.847-.116-1.254" /></g></svg>
                            <span className=" hidden md:flex" >Story</span>
                        </div>
                    </a>
                    <a href="/" onClick={() => handleClick('Igtv')} className="w-[14.2%] h-full py-[8px] sm:py-[6px] px-[14px] md:px-[10px] gap-[6px] flex justify-center items-center text-white border-r-[2px] border-[#ffffff50] group">
                        <div  className="gap-[6px] flex justify-center items-center group-hover:opacity-70 transition-all duration-500 ">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none"><g stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.3"><path d="M12.76 3.482a1.58 1.58 0 0 1 1.58 1.58M13.55 1.111a3.16 3.16 0 0 1 3.16 3.16M14.34 7.433v3.95a1.58 1.58 0 0 1-1.58 1.58H3.278a1.58 1.58 0 0 1-1.58-1.58v-6.32a1.58 1.58 0 0 1 1.58-1.581h7.112M4.858 15.333h6.322" /></g></svg>
                            <span className=" hidden md:flex" >Igtv</span>
                        </div>
                    </a>
                    <a href="/" onClick={() => handleClick('Carousel')} className="w-[14.2%] h-full py-[8px] sm:py-[6px] px-[14px] md:px-[10px] gap-[6px] flex justify-center items-center text-white border-r-[2px] border-[#ffffff50] group">
                        <div className="gap-[6px] flex justify-center items-center group-hover:opacity-70 transition-all duration-500 ">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none"><g stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.3"><path d="M13 15H5c-.265 0-.52-.152-.707-.423-.188-.27-.293-.638-.293-1.021V3.444c0-.383.105-.75.293-1.02C4.481 2.151 4.735 2 5 2h8c.265 0 .52.152.707.423.188.271.293.638.293 1.021v10.111c0 .384-.105.751-.293 1.022-.188.27-.442.423-.707.423ZM3.5 4H2a.953.953 0 0 0-.707.325A1.177 1.177 0 0 0 1 5.111v7.778c0 .295.105.577.293.786A.952.952 0 0 0 2 14h1.5M14.5 4H16c.265 0 .52.117.707.325.188.209.293.491.293.786v7.778c0 .295-.105.577-.293.786A.953.953 0 0 1 16 14h-1.5" /></g></svg>
                            <span className=" hidden md:flex" >Carousel</span>
                        </div>
                    </a>
                    <a href="/"  onClick={() => handleClick('Viewer')} className=" w-[14.2%] h-full py-[8px] sm:py-[6px] px-[14px] md:px-[10px] gap-[6px] flex justify-center items-center text-white ">
                        <div  className="gap-[6px] flex justify-center items-center group-hover:opacity-70 transition-all duration-500">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none"><g stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"><path d="M.75 9s3-6 8.25-6 8.25 6 8.25 6-3 6-8.25 6S.75 9 .75 9Z" /><path d="M9 11.25a2.25 2.25 0 1 0 0-4.5 2.25 2.25 0 0 0 0 4.5Z" /></g></svg>
                            <span className=" hidden md:flex" >Viewer</span>
                        </div>
                    </a>

                </div>

                {/* <h3 className="mt-3 text-white text-[25px] sm:text-[44px] leading-[18px] font-semibold sm:font-bold">Instagram Downloader</h3>
                <p className="text-white text-[18px] leading-[48px] text-center">Download Instagram Videos, Photos, Reels, IGTV</p> */}

                <h3 className="mt-3 text-white text-[25px] sm:text-[44px] leading-[18px] font-semibold sm:font-bold">{currentSelection.title}</h3>
                <p className="text-white text-[18px] leading-[48px] text-center">{currentSelection.description}</p>

                <div className="w-full sm:w-[50%] py-3 sm:p-4 sm:bg-white sm:gap-3 sm:rounded-md flex justify-center items-center">

                    <input
                        type="text"
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                        className="w-full p-4 sm:border-none border border-gray-300 rounded-l-md focus:outline-none  "
                        placeholder="Paste Link here"
                    />
                    {inputValue ? (
                        <button
                            onClick={handleClear}
                            className="px-3 py-4 bg-[#ff5b1984] text-white sm:rounded-md rounded-r-md gap-2 flex items-center"
                        >
                            <MdOutlineClear />
                            Clear
                        </button>
                    ) : (
                        <button
                            onClick={handlePaste}
                            className="px-3 py-4 bg-gray-300 sm:rounded-md rounded-r-md gap-2 flex items-center"
                        >
                            <FaPaste />
                            Paste
                        </button>
                    )}


                    <button
                        onClick={fetchData}
                        className="px-4 py-4 hidden sm:flex justify-center items-center text-white bg-blue-500 rounded-lg hover:bg-gradient-to-r hover:from-[#7873f5] hover:to-[#ec77ab] "
                        disabled={loading}
                    >
                        {loading ? (
                            <svg className="w-5 h-5 mr-3 -ml-1 text-white animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zM2 12c0-5.523 4.477-10 10-10v4a6 6 0 00-6 6H2z"></path>
                            </svg>
                        ) : (
                            'Download'
                        )}
                    </button>
                </div>



                {/* mobile view button 
                ============================================ */}
                <button
                    onClick={fetchData}
                    className="px-4 py-4 w-full sm:w-[50%] sm:hidden flex justify-center items-center text-white bg-blue-500 rounded-lg active:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500"
                    disabled={loading}
                >
                    {loading ? (
                        <svg className=" w-5 h-5 mr-3 -ml-1 text-white animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zM2 12c0-5.523 4.477-10 10-10v4a6 6 0 00-6 6H2z"></path>
                        </svg>
                    ) : (
                        'Download'
                    )}
                </button>
            </div>



            {
                apiResponse &&


                <div className=" py-5 gap-4 flex flex-col justify-center items-center">

                    <div className="w-[75%] sm:w-[50%] lg:w-[20%]  rounded-sm overflow-hidden">
                        <video
                            controls src={apiResponse}>
                        </video>

                    </div>

                    <a
                        // href={apiResponse ? apiResponse : null}
                        download="your-video.mp4"
                        onClick={handleDownload}
                        className="w-[75%] sm:w-[50%] lg:w-[20%] bg-blue-500 active:bg-slate-800 border-double focus:border-3  rounded-lg h-[60px] sm:h-[45px] text-white flex justify-center items-center  ">
                        Download
                    </a>

                </div>

            }

        </>
    );
    
};

export default InputArea;

import React from 'react'
import InputArea from './InputArea'
import HowToDownload from './HowToDownload'
import Faq from './Faq'
import ChooseInstaDL from './ChooseInstaDL'
import Features from './Features'

const Home = () => {
    return (
        <>
            <InputArea />
            <HowToDownload />
            <ChooseInstaDL />
            <Features />
            <Faq />
        </>
    )
}

export default Home

import React from 'react';

import InstaDlPic from '../images/fastDLDownload.jpg';
import copUrlPic from '../images/copyURl.webp';
import pasteUrlPic from '../images/pasteLink.webp';
import downloadPic from '../images/download.webp';
import { FaClipboard, FaPaste, FaDownload } from 'react-icons/fa'; // Importing icons

const HowToDownload = () => {
    const cardData = [
        {
            imgSrc: copUrlPic,
            title: "📋 Copy the URL",
            description: "Open the Instagram app or website. Find your favorite photo, video, Reels, carousel, or IGTV, and copy the URL from the address bar or share menu.",
            icon: <FaClipboard className='text-4xl text-[#1d4ed8]' />,
        },
        {
            imgSrc: pasteUrlPic,
            title: "🔗 Paste the Link",
            description: "Return to the InstaDl website. Simply paste the copied link into the input field and click the 'Download' button to initiate the process.",
            icon: <FaPaste className='text-4xl text-[#1d4ed8]' />,
        },
        {
            imgSrc: downloadPic,
            title: "⬇️ Download",
            description: "In no time, you'll see the download options! Choose your preferred quality and click the download button to save your content directly to your device.",
            icon: <FaDownload className='text-4xl text-[#1d4ed8]' />,
        }
    ];

    return (
        <>
            <div className='px-5 py-10 sm:px-12 md:px-16 lg:px-48 flex justify-center items-center'>
                <div className='lg:h-[250px] bg-white shadow-lg rounded-[12px] flex flex-col sm:flex-row justify-between overflow-hidden'>
                    <div className='sm:w-[33%] overflow-hidden'>
                        <img src={InstaDlPic} alt="InstaDl Overview" className='w-full h-full object-cover' />
                    </div>
                    <div className='sm:w-[65%] p-7 gap-5 flex flex-col justify-center items-center'>
                        <h2 className='text-[#1d4ed8] text-[24px] sm:text-[32px] font-bold leading-[30px] sm:leading-[38px]'>
                            Download Instagram Videos and Photos Effortlessly!
                        </h2>
                        <p className='text-[18px] text-[#000000B3] font-normal leading-[28px]'>
                            InstaDl is your go-to online tool for downloading Instagram content. Whether it's Videos, Photos, Reels, or IGTV, we've made it easy for you to save your favorites on any device.
                        </p>
                    </div>
                </div>
            </div>

            <div className='px-5 py-10 sm:px-24 md:px-32 lg:px-48'>
                <div className='text-center'>
                    <h2 className='py-4 text-[#1d4ed8] text-[24px] sm:text-[32px] font-bold leading-[100%]'>
                        How to Download from Instagram?
                    </h2>
                    <div className='w-full h-[2px] bg-gradient-to-tr from-[#1d4ed8] to-pink-600' />
                    <p className='py-4 text-[18px] text-[#000000B3] font-normal leading-[28px]'>
                        Follow these three simple steps to download videos, Reels, and photos from Instagram (IG, Insta). It’s quick and easy!
                    </p>
                </div>
            </div>

            {/* Steps Section */}
            <div className='px-5 py-10 sm:px-12 md:px-16 lg:px-48'>
                <div className='gap-6 grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3'>
                    {cardData.map((card, index) => (
                        <div key={index} className='w-full h-full bg-white rounded-[12px] flex flex-col items-center shadow-md overflow-hidden transition-transform transform hover:scale-105'>
                            <div className='w-full h-[50%] overflow-hidden flex items-center justify-center'>
                                {card.icon}
                                <img src={card.imgSrc} alt="" className='w-full h-full object-cover opacity-70' />
                            </div>
                            <div className='p-4'>
                                <div className='py-4 border-b-[2px] border-[#1d4ed8]'>
                                    <h2 className='text-center text-[#be185d] text-[24px] font-bold leading-[28px]'>
                                        {card.title}
                                    </h2>
                                </div>
                                <p className='py-3 text-[18px] text-[#000000B3] font-normal leading-[28px]'>
                                    {card.description}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}

export default HowToDownload;

import React from 'react'

const Contact = () => {
  return (
    <>


      <div className='px-5 py-10 sm:px-24 md:px-32 lg:px-48 '>

        <div className='text-center '>
          <h2 className='py-4 text-[#1d4ed8] text-[24px] sm:text-[32px] font-bold leading-[100%] '>
            Contact US
          </h2>

          {/* <div className='w-full h-[2px] bg-gradient-to-tr from-[#1d4ed8] to-pink-600 border-[#1d4ed8] '>
          </div> */}

          <p className='py-4 text-[18px] text-[#000000B3] font-normal leading-[28px] '>
            Do you want to know more about how we could help you? Don't hesitate to get in touch with us.
          </p>
          <p className='py-4 text-[18px] text-[#000000B3] font-normal leading-[28px] '>
            Contact us by email: <a href="" className='py-4 text-[#1d4ed8] underline font-bold'>xelinzo007@gmail.com</a>
          </p>

        </div>

      </div>
    </>
  )
}

export default Contact
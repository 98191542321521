import React from 'react';

import Pic1 from '../images/1.webp';
import Pic2 from '../images/2.webp';
import Pic3 from '../images/3.webp';
import Pic4 from '../images/4.webp';
import Pic5 from '../images/5.webp';
import { FaVideo, FaPhotoVideo, FaFilm, FaTv, FaImages } from 'react-icons/fa'; // Importing icons

const Features = () => {
    const instaDlData = [
        {
            image: Pic1,
            title: "📹 Video Downloader",
            description: "Effortlessly download your favorite Instagram videos with InstaDl! Whether it's a single video or a carousel of content, we ensure a seamless experience for saving IG videos from your personal account.",
            icon: <FaVideo className='text-4xl text-[#1d4ed8]' />,
        },
        {
            image: Pic2,
            title: "📷 Photos Downloader",
            description: "Save stunning Instagram photos directly to your device. InstaDl allows you to download both individual images and multiple photos from carousel posts, preserving their quality.",
            icon: <FaImages className='text-4xl text-[#1d4ed8]' />,
        },
        {
            image: Pic3,
            title: "🎥 Reels Downloader",
            description: "Capture the magic of Instagram Reels! With our specialized downloader, you can save your favorite Reels videos and enjoy them offline anytime you want.",
            icon: <FaFilm className='text-4xl text-[#1d4ed8]' />,
        },
        {
            image: Pic4,
            title: "📺 IGTV Downloader",
            description: "Never miss out on your favorite IGTV episodes! Download long-format videos directly to your device for offline viewing whenever you choose.",
            icon: <FaTv className='text-4xl text-[#1d4ed8]' />,
        },
        {
            image: Pic5,
            title: "🖼️ Carousel / Album Downloader",
            description: "Easily download multiple photos and videos from Instagram carousels or albums. InstaDl is your go-to tool for managing your gallery collection effortlessly.",
            icon: <FaPhotoVideo className='text-4xl text-[#1d4ed8]' />,
        },
    ];

    return (
        <div className='px-5 py-10 sm:px-24 md:px-32 lg:px-48'>
            <div className='text-center'>
                <h2 className='py-4 text-[#1d4ed8] text-[24px] sm:text-[32px] font-bold leading-[100%]'>
                    Discover the Powerful Features of InstaDl
                </h2>
                <div className='w-full h-[2px] bg-gradient-to-tr from-[#1d4ed8] to-pink-600' />
                <p className='py-4 text-[18px] text-[#000000B3] font-normal leading-[28px]'>
                    With InstaDl, unleash the full potential of downloading Instagram content effortlessly. Our intuitive platform supports a wide range of media types.
                </p>
            </div>

            <div className='px-5 sm:px-12 md:px-16 lg:px-48 gap-5 flex flex-col'>
                {instaDlData.map((item, index) => (
                    <div key={index} className={`lg:h-[250px] bg-white shadow-lg rounded-[12px] flex flex-col sm:flex-row ${index % 2 === 0 ? 'sm:flex-row-reverse' : ''} justify-between overflow-hidden mb-5 transition-transform transform hover:scale-105`}>
                        <div className='sm:w-[33%] overflow-hidden'>
                            <img src={item.image} alt={item.title} className='w-full h-full object-cover' />
                        </div>
                        <div className='sm:w-[65%] p-7 gap-5 flex flex-col justify-center items-start'>
                            <div className='flex items-center'>
                                {item.icon}
                                <h2 className='ml-3 text-[#1d4ed8] text-[24px] sm:text-[28px] font-bold leading-[30px] sm:leading-[38px]'>
                                    {item.title}
                                </h2>
                            </div>
                            <p className='text-[16px] text-[#000000B3] font-normal leading-[28px]'>
                                {item.description}
                            </p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Features;

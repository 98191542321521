import React, { useState } from 'react';
import { Outlet, Link as RouterLink } from 'react-router-dom'; // Alias react-router-dom's Link
import { Link as ScrollLink } from 'react-scroll'; // Alias react-scroll's Link

const Navbar = () => {
    // State for selected language
    const [language, setLanguage] = useState('EN');

    // Texts for different languages
    const textContent = {
        EN: {
            title: 'InstaDL',
            faq: 'FAQ',
        },
        ES: {
            title: 'InstaDL (Español)',
            faq: 'Preguntas Frecuentes',
        },
        FR: {
            title: 'InstaDL (Français)',
            faq: 'FAQ',
        },
        DE: {
            title: 'InstaDL (Deutsch)',
            faq: 'Häufige Fragen',
        },
        IT: {
            title: 'InstaDL (Italiano)',
            faq: 'Domande Frequenti',
        },
    };

    // Handle language change
    const handleLanguageChange = (e) => {
        setLanguage(e.target.value);
        // Here you could implement a more sophisticated logic to update the text throughout the app
        // For example, using context to propagate the change
    };

    return (
        <>
            <div className='w-full px-5 sm:px-24 h-[70px] bg-white p-4 border-b-2 flex justify-between items-center'>
                <div className='gap-2 flex items-center'>
                    <RouterLink to='/' className='text-[30px] font-semibold gradient-text'>
                        {textContent[language].title}
                    </RouterLink>
                </div>

                <div className='flex items-center gap-3'>
                    <ScrollLink to='faq' className='cursor-pointer' smooth={true} duration={500}>
                        {textContent[language].faq}
                    </ScrollLink>

                    <select className="bg-transparent custom-select my-1 mr-sm-2" onChange={handleLanguageChange} value={language}>
                        <option value="EN">EN</option>
                        {/* <option value="ES">ES</option>
                        <option value="FR">FR</option>
                        <option value="DE">DE</option>
                        <option value="IT">IT</option> */}
                    </select>
                </div>
            </div>
            <Outlet />
        </>
    );
}

export default Navbar;


import React from "react";

import InputArea from "./components/InputArea";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Faq from "./components/Faq";
import HowToDownload from "./components/HowToDownload";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import Privacy_Policy from "./components/Privacy_Policy";
import Contact from "./components/Contact";
import Terms_and_Conditions from "./components/Terms_and_Conditions";

function App() {



  return (
    <>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route index element={<Home />} />
          <Route path="/privacy-policy" element={<Privacy_Policy />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/terms-conditions" element={<Terms_and_Conditions />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;

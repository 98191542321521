import React from 'react'

import downloadIcon from '../images/download-file.png'
import tvIcon from '../images/tv-monitor.png'
import likeIcon from '../images/thumb-up.png'
import shieldIcon from '../images/shield.png'

const ChooseInstaDL = () => {
    const downloadFeatures = [
        {
            title: "Lightning-Fast Downloads",
            description: "Experience download speeds that will leave you amazed. Our cutting-edge servers ensure that you can grab your favorite content in mere seconds!",
            icon: downloadIcon,
        },
        {
            title: "Universal Compatibility",
            description: "No matter where you are or what device you're using—mobile, tablet, or desktop—InstaDL makes downloading effortless and accessible for everyone.",
            icon: tvIcon,
        },
        {
            title: "Pristine Quality",
            description: "Why settle for less? Download Instagram content in its original glory—high resolution and high quality, just as it was meant to be enjoyed.",
            icon: likeIcon,
        },
        {
            title: "Top-Notch Security",
            description: "Your privacy is our priority. With no login required and all downloads securely processed, you can download confidently without any worries.",
            icon: shieldIcon,
        },
    ];

    return (
        <>
            <div className='px-5 py-10 sm:px-24 md:px-32 lg:px-48'>
                <div className='flex flex-col items-center'>
                    <h3 className='py-4 text-[#1d4ed8] text-[25px] sm:text-[32px] font-bold text-center'>Why Choose InstaDL for Instagram Downloads?</h3>
                    
                    <div className='w-full h-[2px] bg-gradient-to-tr from-[#1d4ed8] to-pink-600' />
                    
                    <p className='py-4 text-[18px] font-normal text-[#000000B3] leading-[28px] text-center'>
                        Downloading videos from Instagram has never been easier! With just two clicks, you can save stunning videos without sacrificing quality. Skip the unreliable apps and elevate your viewing experience.
                    </p>

                    <div className='py-4 px-4 lg:px-16 gap-10 lg:gap-28 lg:gap-y-14 grid md:grid-cols-2'>
                        {downloadFeatures.map((feature, index) => (
                            <div key={index} className='text-center gap-4 flex flex-col items-center transition-transform transform hover:scale-105'>
                                <img className='w-[42px] mb-2' src={feature.icon} alt={feature.title} />
                                <div className='w-full pb-4 border-b-[2px] border-[#1d4ed8]'>
                                    <h2 className='text-center text-[#be185d] text-[24px] font-bold leading-[28px]'>
                                        {feature.title}
                                    </h2>
                                </div>
                                <p className='py-3 text-[18px] text-[#000000B3] font-normal leading-[28px]'>
                                    {feature.description}
                                </p>
                            </div>
                        ))}
                    </div>

                </div>
            </div>
        </>
    )
}

export default ChooseInstaDL
